import axios from "axios";

export const instance = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_API_KEY,
});

// export const instance = axios.create({
//     // .. where we make our configurations
//         baseURL: 'https://appraisalapi.rcktechiees.com/ABInspect.asmx/'
//     });
