import {
  browserName,
  browserVersion,
  osVersion,
  osName,
  mobileModel,
  isIOS,
  engineName,
  engineVersion,
  mobileVendor,
  deviceType,
} from "react-device-detect";
import React from "react";
import { instance } from "./interceptor";

function error(err) {
  if (err) {
    return err.toString();
  } else {
    return "On Page Load";
  }
}

const deviceInfo = (base64ToStringNew, err) => {
  let datas = {
    leadid: base64ToStringNew,
    msg: error(err),
    device:
      "[" +
      browserName +
      "]" +
      "[" +
      browserVersion +
      "]" +
      "[" +
      osName +
      "]" +
      "[" +
      osVersion +
      "]" +
      "[" +
      mobileModel +
      "]",
  };
  if (base64ToStringNew != "") {
    instance.post("DeviceAndErrorInfos", datas, {});
  }

  return {
    leadid: base64ToStringNew,
    msg: error(err),
    device:
      "[" +
      browserName +
      "]" +
      "[" +
      browserVersion +
      "]" +
      "[" +
      osName +
      "]" +
      "[" +
      osVersion +
      "]" +
      "[" +
      mobileModel +
      "]",
  };
};

export default deviceInfo;
